import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { navigate } from "gatsby"
import {Cookies} from 'react-cookie'

let cookie = new Cookies();

    var liveVersion = cookie.get('appVersion');
    //console.log("liveVersion::",liveVersion);
    if(liveVersion!==undefined && liveVersion!=='' && liveVersion!==null){
        liveVersion = liveVersion.split('-');
        if(parseInt(liveVersion[0])<=1 && parseInt(liveVersion[1])<=17){
            liveVersion= true;
        }else{
            liveVersion = false;
        }
    }else{
        liveVersion = true;
    }

const Terms = () => (

  
  <Layout title={"FAQ"}>
    <Seo title="FAQ" />
    
    <section className="content-section">
      <div className="container" style={{marginTop:'50px'}}>
      <p><b>Here are some frequently asked questions (FAQs) for https://121islam.com:</b></p>

<p><b>Q: What kind of online classes does 121islam.com provide? </b><br/><br/><b>A:</b> 121islam.com provides online classes in Arabic, Islamic science, and Quran. The website and mobile application offer live classes taught by verified teachers.</p>

<p><b>Q: Who can sign up for classes on 121islam.com? </b><br/><br/><b>A:</b> Anyone around the world can sign up for classes on 121islam.com. Students or parents of students can create an account and book lessons with verified teachers.</p>

<p><b>Q: How do I sign up for classes on 121islam.com? </b><br/><br/><b>A:</b> To sign up for classes, simply create an account on the website or mobile application. You can then top up your account and book lessons for one or multiple children.</p>

<p><b>Q: How do I know if a teacher on 121islam.com is verified? </b><br/><br/><b>A:</b> All teachers on 121islam.com go through a screening process to ensure their qualifications and experience meet the platform's standards.</p>

<p><b>Q: What kind of packages does 121islam.com offer? </b><br/><br/><b>A:</b> 121islam.com offers different packages to suit different needs and budgets. You can choose from various packages that include different numbers of lessons, with different durations and prices.</p>

<p><b>Q: Is the first lesson on 121islam.com free? </b><br/><br/><b>A:</b> Yes, the first lesson on 121islam.com is free of charge. This allows you to try out the platform and ensure that it meets your needs.</p>

<p><b>Q: How does the teacher rating system work on 121islam.com? </b><br/><br/><b>A:</b> 121islam.com's rating system allows only the best teachers on the platform. Students or parents of students can rate the teachers they work with, and these ratings help ensure that the highest quality teachers are available to all users.</p>

<p><b>Q: What happens to the profits made by 121islam.com? </b><br/><br/><b>A:</b> All profits made by 121islam.com go to charity, supporting a range of humanitarian and educational initiatives around the world.</p>

<p><b>Q: Does 121islam.com allow children to have lessons with teachers of the opposite gender? </b><br/><br/><b>A:</b> No, 121islam.com does not allow children of a certain age to have lessons with teachers of the opposite gender. The platform takes the safety and privacy of children very seriously and implements measures to ensure that children are not placed in potentially inappropriate situations.</p>

<p><b>Q: How do I access the website or mobile application? </b><br/><br/><b>A:</b> You can access the website by typing https://121islam.com into your web browser. To use the mobile application, you can download it from the App Store or Google Play Store.</p>

{liveVersion && <p><b>Q: How do I make a payment? </b><br/><br/><b>A:</b> Payments can be made through the website or mobile application using a credit or debit card. All payments are processed securely to protect your personal and financial information.</p>}

{liveVersion && <p><b>Q: Is it safe to use my credit or debit card on the website or mobile application? </b><br/><br/><b>A:</b> We take the security of our users' personal and financial information very seriously. All payments are processed securely using industry-standard encryption technology. However, we recommend that you also take appropriate measures to protect your personal and financial information, such as using strong passwords and keeping your computer and mobile devices secure.</p>}

<p><b>Q: What if I suspect fraud on my account? </b><br/><br/><b>A:</b> If you suspect fraudulent activity on your account, please contact us immediately at support@121islam.com. We will work with you to investigate the matter and take appropriate action to protect your account and personal information.</p>

<p><b>Q: How can I contact customer support? </b><br/><br/><b>A:</b> You can contact customer support by emailing us at support@121islam.com. We strive to respond to all inquiries within 24 hours.</p>
      </div>
    </section>     
  </Layout>
)

export default Terms
